import * as React from 'react';
import axios from "axios";
import {Link, useSearchParams} from 'react-router-dom';
import {Card, Stack, Typography, TextField, FormControl, FormLabel, Button, Box} from '@mui/material'
import {useTheme} from "@mui/material/styles";
import LoadingWheel from "src/components/theme/loading";

export default function PasswordResetRequest() {
    const [email, setEmail] = React.useState('');
    const [emailError, setEmailError] = React.useState(false);
    const [emailErrorMessage, setEmailErrorMessage] = React.useState('');
    const [emailSent, setEmailSent] = React.useState(false);
    const [isLoading, setIsLoading] = React.useState(false);
    const theme = useTheme();

    const resetRequest = () => {
        setIsLoading(true);
        if (!email || !/\S+@\S+\.\S+/.test(email)) {
            setEmailError(true);
            setEmailErrorMessage('Please enter a valid email address.');
        } else {
            setEmailError(false);
            setEmailErrorMessage('');
            axios.post(process.env.REACT_APP_API_BASE_URL + 'auth/password/reset/request',
                {
                    email: email
                },
            ).then(response => {
                console.log(response);
                setIsLoading(false);
                setEmailSent(true);
            }).catch(error => {
                console.error(error);
                setEmailError(true);
                if (error.response.data.error === "User matching query does not exist.") {
                    setEmailErrorMessage("No user found with this email.");
                } else {
                    setEmailErrorMessage(error.response.data.error);
                }
                setIsLoading(false);
            });
        }
    }

    return (
        <Stack
            direction="row"
            justifyContent="center"
            alignItems="center"
            sx={{width: 1, height: "100vh", backgroundColor: theme.palette.background.default}}
        >
            {emailSent ? <Card variant={'outlined'}
                               sx={{
                                   justifyContent: "space-between",
                                   width: "400px",
                                   backgroundColor: theme.palette.primary.main
                               }}>
                    <Typography
                        component="h1"
                        variant="h4"
                        sx={{width: '100%', marginBottom: '20px'}}
                    >
                        Reset Link Sent!
                    </Typography>
                    <Typography
                        component="h5"
                        variant="h5"
                        sx={{width: '100%', marginBottom: '20px', marginTop: '20px'}}
                    >
                        Please check your emails to set a new password.
                    </Typography>

                </Card>
                : <Card variant={'outlined'}
                        sx={{
                            justifyContent: "space-between",
                            width: "400px",
                            backgroundColor: theme.palette.primary.main
                        }}>
                    <Typography
                        component="h1"
                        variant="h4"
                        sx={{width: '100%', marginBottom: '20px'}}
                    >
                        Reset Password
                    </Typography>
                    <Box
                        sx={{
                            display: 'flex',
                            flexDirection: 'column',
                            width: '100%',
                            gap: 2,
                        }}
                    >
                        <FormControl>
                            <Box sx={{display: 'flex', justifyContent: 'space-between'}}>
                                <FormLabel sx={{color: 'text.primary', margin: 1}} htmlFor="email">Email</FormLabel>
                            </Box>
                            <TextField
                                error={emailError}
                                helperText={emailErrorMessage}
                                value={email}
                                onChange={(e) => setEmail(e.target.value)}
                                id="email"
                                type="email"
                                name="email"
                                placeholder="your@email.com"
                                autoComplete="email"
                                autoFocus
                                required
                                fullWidth
                                variant="outlined"
                                color={emailError ? 'error' : 'primary'}
                                sx={{ariaLabel: 'email', border: 'none'}}
                            />
                        </FormControl>
                        {isLoading ? <LoadingWheel color={theme.palette.secondary.main}/> : <Button
                            type="submit"
                            fullWidth
                            variant="contained"
                            onClick={resetRequest}
                            sx={{color: theme.palette.text.secondary, fontWeight: 'bold'}}
                        >
                            Send Password Reset Email
                        </Button>}
                        <Typography sx={{textAlign: 'center'}}>
                            Already know your password?{' '}
                            <span>
        <Link to={'/login'} style={{color: theme.palette.text.secondary, textDecoration: 'none'}}>Log In</Link>
    </span>
                        </Typography>
                    </Box>
                </Card>}
        </Stack>
    );
}