import {Box, Card, Button} from '@mui/material';
import Grid from '@mui/material/Grid2';
import Typography from "@mui/material/Typography";
import {useNavigate, useParams} from "react-router-dom";
import {Outlet, Navigate} from 'react-router-dom'

export default function Recruitment() {
    const navigate = useNavigate();

    return (
        <Box>
            <Grid container spacing={2}>
                <Grid size={12}>
                    <Card>
                        <Typography variant="h5" component="div">Recruitment</Typography>
                    </Card>

                    <Grid container>
                        <Grid size={2}>
                            <Card variant={'outlined'} sx={{marginTop: '20px', marginRight: '20px'}}>
                                <Button onClick={()=> navigate('/recruitment/job/openings')} sx={{textAlign: 'left'}}>
                                    Manage Job Openings
                                </Button>
                                <Button onClick={()=> navigate('/recruitment/talent')} sx={{textAlign: 'left'}}>
                                    Manage Talent Pool
                                </Button>
                                <Button onClick={()=> navigate('/recruitment/job/descriptions')} sx={{textAlign: 'left'}}>
                                    Manage Job Descriptions
                                </Button>
                            </Card>
                        </Grid>
                        <Grid size={10} sx={{marginTop: '20px'}}>
                            <Outlet/>
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
        </Box>
    );
}