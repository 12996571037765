import {SetStateAction, useEffect, useState} from 'react';
import {
    Editor as WYSIWYG,
    EditorProvider,
    Toolbar,
    BtnBold,
    BtnItalic,
    BtnStrikeThrough,
    BtnBulletList,
    BtnNumberedList,
    BtnUndo,
    BtnRedo,
    createButton
} from 'react-simple-wysiwyg';
import {FaAlignLeft, FaAlignCenter, FaAlignRight} from 'react-icons/fa';

const BtnAlignLeft = createButton('Align center', <FaAlignLeft/>, 'justifyLeft');
const BtnAlignCenter = createButton('Align center', <FaAlignCenter/>, 'justifyCenter');
const BtnAlignRight = createButton('Align right', <FaAlignRight/>, 'justifyRight');


interface Props {
    html: string
    onChange?: Function
}

export default function Editor(props: Props) {
    const [html, setHtml] = useState(props.html);

    useEffect(() => {
        setHtml(props.html);
    }, [props.html]);

    function onChange(e: { target: { value: SetStateAction<string>; }; }) {
        setHtml(e.target.value);
        props.onChange && props.onChange(e.target.value);
    }

    return (
        <EditorProvider>
            <WYSIWYG value={html} onChange={onChange}>
                <Toolbar>
                    <BtnUndo/>
                    <BtnRedo/>
                    <BtnBold/>
                    <BtnItalic/>
                    <BtnStrikeThrough/>
                    <BtnAlignLeft/>
                    <BtnAlignCenter/>
                    <BtnAlignRight/>
                    <BtnBulletList/>
                    <BtnNumberedList/>
                </Toolbar>
            </WYSIWYG>
        </EditorProvider>
    );
}