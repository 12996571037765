import {MinutesInterface} from "src/components/meeting/meeting";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";

interface Props {
    minutes: MinutesInterface
}

export default function MinutesItem(props: Props) {
    return (
        <Box
            display="flex"
            justifyContent="space-between"
            alignItems="center"
        >
            <Box lineHeight={1.125}>
                <Typography variant="body1" component="span" sx={{color: 'white'}}>
                    Agenda created on {new Date(props.minutes.created!).toLocaleDateString('en-GB')}
                </Typography>

            </Box>
            <Box display="flex" alignItems="center">
                <Button>
                    Edit
                </Button>
                <Button>
                    View
                </Button>
            </Box>
        </Box>
    );
}
