import {useEffect, useState} from "react";
import axios from "axios";

interface Props {
    content: string|null
}

export default function SearchResult(props:Props) {

    return (
        <div
            style={{
                padding: "12px",
                margin: '20px',
                display: "flex",
                justifyContent: "start",
                gap: "0.5em",
                alignItems: "center",
            }}
        >
            {props.content}

        </div>
    );
}