import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { Card, Typography, TextField, Button, Box, FormControl, FormLabel } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import LoadingWheel from "src/components/theme/loading";

export default function AccountSettings() {
    const [isPasswordSet, setIsPasswordSet] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const [errorMessage, setErrorMessage] = useState<string | null>(null);
    const [successMessage, setSuccessMessage] = useState<string | null>(null);
    const theme = useTheme();

    useEffect(() => {
        const checkPasswordStatus = async () => {
            try {
                setIsLoading(true);
                const response = await axios.get(process.env.REACT_APP_API_BASE_URL + 'auth/user', {
                    withCredentials: true,
                });
                setIsPasswordSet(response.data.user.password_set);
                setIsLoading(false);

            } catch (error) {
                console.error('Error checking password status:', error);
                setIsPasswordSet(true);
                setIsLoading(false);
            }
        };

        checkPasswordStatus();
    }, []);


    const handlePasswordUpdate = (event: React.FormEvent) => {
        event.preventDefault();
        setSuccessMessage(null);
        setIsLoading(true);

        const currentPassword = (document.getElementById('current-password') as HTMLInputElement)?.value;
        const newPassword = (document.getElementById('new-password') as HTMLInputElement)?.value;
        const confirmPassword = (document.getElementById('confirm-password') as HTMLInputElement)?.value;

        if (newPassword !== confirmPassword) {
            setErrorMessage("New passwords do not match");
            setIsLoading(false);
            return;
        }

        const endpoint = isPasswordSet
            ? process.env.REACT_APP_API_BASE_URL + 'auth/password/change'
            : process.env.REACT_APP_API_BASE_URL + 'auth/password/set';

        const payload = isPasswordSet
            ? { old_password: currentPassword, new_password: newPassword }
            : { password: newPassword };

        axios.post(
            endpoint,
            payload,
            { withCredentials: true }
        ).then(() => {
            if (!isPasswordSet) {
                setIsPasswordSet(true);
            }
            setErrorMessage(null);
            setSuccessMessage('Password updated successfully');
            setIsLoading(false);
        }).catch(error => {
            setIsLoading(false);
            setErrorMessage(error.response?.data?.error || 'An error occurred')
        });
    };

    // Common styles
    const commonCardStyle = {
        justifyContent: "space-between",
        width: "90%",
        backgroundColor: theme.palette.primary.main,
        margin: '0 auto'
    };

    const commonFormStyle = {
        display: 'flex',
        flexDirection: 'column',
        width: '100%',
        gap: 2,
    };

    // Reusable message component
    const MessageCard = ({ message, isError }: { message: string, isError: boolean }) => (
        <Card sx={{ backgroundColor: theme.palette.secondary.main, padding: 2, marginBottom: 2 }}>
            <Typography
                sx={{
                    color: isError ? theme.palette.error.main : theme.palette.success.main,
                    fontWeight: 'bold',
                }}
            >
                {message}
            </Typography>
        </Card>
    );

    // Reusable password field component
    const PasswordField = ({ id, label, autoFocus = false }: { id: string, label: string, autoFocus?: boolean }) => (
        <FormControl>
            <FormLabel sx={{ color: 'text.primary', margin: 1 }} htmlFor={id}>{label}</FormLabel>
            <TextField
                name={id}
                placeholder="••••••"
                type="password"
                id={id}
                autoComplete={id === 'current-password' ? 'current-password' : 'new-password'}
                autoFocus={autoFocus}
                required
                fullWidth
                variant="outlined"
                color={errorMessage ? 'error' : 'primary'}
            />
        </FormControl>
    );

    function ChangePassword() {
        return (
            <Card variant={'outlined'} sx={commonCardStyle}>
                <Typography component="h5" variant="h5" sx={{ width: '100%', marginBottom: '20px' }}>
                    Change Password
                </Typography>
                {errorMessage && <MessageCard message={errorMessage} isError={true} />}
                {successMessage && <MessageCard message={successMessage} isError={false} />}
                <Box component="form" onSubmit={handlePasswordUpdate} noValidate sx={commonFormStyle}>
                    <PasswordField id="current-password" label="Current Password" />
                    <PasswordField id="new-password" label="New Password" />
                    <PasswordField id="confirm-password" label="Confirm New Password" />
                    <Button
                        type="submit"
                        fullWidth
                        variant="contained"
                        sx={{ color: theme.palette.text.secondary, fontWeight: 'bold' }}
                    >
                        Change Password
                    </Button>
                </Box>
            </Card>
        );
    }

    function SetPassword() {
        return (
            <Card variant={'outlined'} sx={commonCardStyle}>
                <Typography component="h5" variant="h5" sx={{ width: '100%', marginBottom: '20px' }}>
                    Set Password
                </Typography>
                {errorMessage && <MessageCard message={errorMessage} isError={true} />}
                {successMessage && <MessageCard message={successMessage} isError={false} />}
                <Box component="form" onSubmit={handlePasswordUpdate} noValidate sx={commonFormStyle}>
                    <PasswordField id="new-password" label="Password" autoFocus={true} />
                    <PasswordField id="confirm-password" label="Confirm Password" />
                    <Button
                        type="submit"
                        fullWidth
                        variant="contained"
                        sx={{ color: theme.palette.text.secondary, fontWeight: 'bold' }}
                    >
                        Set Password
                    </Button>
                </Box>
            </Card>
        );
    }

    return (
        <Box>
            {isLoading ? (
                <LoadingWheel />
            ) : (
                isPasswordSet ? <ChangePassword /> : <SetPassword />
            )}
        </Box>
    );
}
