import {Button, Card, Typography, Grid2} from "@mui/material";
import JobOpeningsList from "src/components/recruitment/job_openings/job_openings_list";
import {FaPlus} from "react-icons/fa";
import {useNavigate} from "react-router-dom";

export default function JobOpenings() {
    const navigate = useNavigate();
    return (
        <Grid2 size={12}>
            <Card sx={{marginBottom: '20px', display: 'flex', justifyContent: 'space-between'}}>
                <Typography variant="h5" component="div">Job Openings</Typography>
                <Button onClick={() => navigate('/recruitment/job/opening/create')}
                        sx={{padding: 0, color: 'primary.dark', backgroundColor: 'white'}}><FaPlus size={20}/></Button>
            </Card>
            <JobOpeningsList/>
        </Grid2>
    )

}
