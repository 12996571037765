import * as React from 'react';
import axios from "axios";
import {useNavigate, Link, useSearchParams} from 'react-router-dom';
import {useAuth} from "src/components/auth/AuthProvider";
import {Card, Stack, Typography, TextField, FormControl, FormLabel, Button, Box} from '@mui/material'
import {useTheme} from "@mui/material/styles";
import {useEffect} from "react";
import LoadingWheel from "src/components/theme/loading";

export default function PasswordResetRequest() {
    const [password, setPassword] = React.useState('');
    const [passwordError, setPasswordError] = React.useState(false);
    const [passwordErrorMessage, setPasswordErrorMessage] = React.useState('');
    const [isLoading, setIsLoading] = React.useState(false);
    const navigate = useNavigate();
    const theme = useTheme();
    const [params] = useSearchParams();
    const {login} = useAuth();

    const resetRequest = () => {
        setIsLoading(true);
        setPasswordError(false);
        setPasswordErrorMessage('');
        axios.post(process.env.REACT_APP_API_BASE_URL + 'auth/password/reset',
            {
                email: params.get('email'),
                password: password,
                token: params.get('token')
            },
        ).then(response => {
            console.log(response);
            login(response.data['user']);
            navigate('/');
            setIsLoading(false);
        }).catch(error => {
            console.error(error);
            setPasswordError(true);
            const errors = JSON.parse(error.response?.data?.error.replace(/'/g, '"'));

            if (Array.isArray(errors) && errors.length > 0) {
                setPasswordErrorMessage(errors[0]);
            } else {
                setPasswordErrorMessage("An unexpected error occurred.");
            }
        });
    }

    return (
        <Stack
            direction="row"
            justifyContent="center"
            alignItems="center"
            sx={{width: 1, height: "100vh", backgroundColor: theme.palette.background.default}}
        >
            <Card variant={'outlined'}
                  sx={{
                      justifyContent: "space-between",
                      width: "400px",
                      backgroundColor: theme.palette.primary.main
                  }}>
                <Typography
                    component="h1"
                    variant="h4"
                    sx={{width: '100%', marginBottom: '20px'}}
                >
                    Set New Password
                </Typography>
                <Box
                    sx={{
                        display: 'flex',
                        flexDirection: 'column',
                        width: '100%',
                        gap: 2,
                    }}
                >
                    <FormControl>
                        <Box sx={{display: 'flex', justifyContent: 'space-between'}}>
                            <FormLabel sx={{color: 'text.primary', margin: 1}} htmlFor="password">New
                                Password</FormLabel>
                        </Box>
                        <TextField
                            error={passwordError}
                            helperText={passwordErrorMessage}
                            value={password}
                            onChange={(e) => setPassword(e.target.value)}
                            name="password"
                            placeholder="••••••"
                            type="password"
                            id="passwordConfirmation"
                            autoFocus
                            required
                            fullWidth
                            variant="outlined"
                            color={passwordError ? 'error' : 'primary'}
                            sx={{ariaLabel: 'password', border: 'none'}}
                        />
                    </FormControl>
                    {isLoading ? <LoadingWheel color={theme.palette.secondary.main}/>
                        : <Button
                            type="submit"
                            fullWidth
                            variant="contained"
                            onClick={resetRequest}
                            sx={{color: theme.palette.text.secondary, fontWeight: 'bold'}}
                        >
                            Reset Password
                        </Button>}
                    <Typography sx={{textAlign: 'center'}}>
                        Already know your password?{' '}
                        <span>
        <Link to={'/login'} style={{color: theme.palette.text.secondary, textDecoration: 'none'}}>Log In</Link>
    </span>
                    </Typography>
                </Box>
            </Card>
        </Stack>
    );
}