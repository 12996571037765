import {AttendeeInterface} from "@/components/meeting/meeting";
import AttendeeItem from './attendee_item';
import LoadingWheel from "../../../theme/loading";
import Card from "@mui/material/Card";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import {theme} from "src/components/theme/theme";

interface Props {
    attendees: AttendeeInterface[]
}

export default function Attendees(props: Props) {
    return (
        <Card sx={{backgroundColor: theme.palette.primary.main, marginBottom: '20px'}}>
            <Box>
                <Typography variant="h5" component="div" >
                    Attendees
                </Typography>
            </Box>
            <Box>
                {props.attendees ?
                    <Box>
                        {props.attendees.length > 0 ?
                            props.attendees.map((attendee: AttendeeInterface) => (
                                <AttendeeItem attendee={attendee}/>
                            )) : "No attendees found"}
                    </Box>
                    : <LoadingWheel/>}
            </Box>
        </Card>
    );
}