import * as React from 'react';
import axios from "axios";
import { useNavigate, Link, useSearchParams } from 'react-router-dom';
import { useAuth } from "src/components/auth/AuthProvider";
import { Card, Stack, Typography, TextField, FormControl, FormLabel, Button, Box } from '@mui/material'
import { useTheme } from "@mui/material/styles";
import { useEffect } from "react";
import LoadingWheel from 'src/components/theme/loading';

export default function Login() {
    const [emailError, setEmailError] = React.useState(false);
    const [emailErrorMessage, setEmailErrorMessage] = React.useState('');
    const [passwordError, setPasswordError] = React.useState(false);
    const [passwordErrorMessage, setPasswordErrorMessage] = React.useState('');
    const [errorMessage, setErrorMessage] = React.useState<string | null>(null);
    const [isLoading, setIsLoading] = React.useState(true);
    const navigate = useNavigate();
    const { login } = useAuth();
    const theme = useTheme();
    const [params] = useSearchParams();

    useEffect(() => {
        setIsLoading(true);
        if (params.get('email') && params.get('password')) {
            loginRequest(params.get('email') as string, params.get('password') as string)
        }
        setIsLoading(false);
    }, [params]);

    const handleSubmit = (event: React.FormEvent<HTMLFormElement>) => {
        event.preventDefault();
        setIsLoading(true);
        const data = new FormData(event.currentTarget);
        console.log({
            email: data.get('email'),
            password: data.get('password'),
        });

        loginRequest(data.get('email') as string, data.get('password') as string)
    };

    function loginRequest(email: string, password: string) {
        axios.post(process.env.REACT_APP_API_BASE_URL + 'auth/login',
            {
                email: email,
                password: password
            },
            {
                withCredentials: true
            }
        ).then(response => {
            console.log(response);

            login(response.data.user);
            navigate('/');
        }).catch(error => {
            setIsLoading(false);
            setErrorMessage(error.response?.data?.error || 'Sorry, we are having trouble logging you in. Please try again later.');

            console.error(error);
        });
    }

    const validateInputs = () => {
        const email = document.getElementById('email') as HTMLInputElement;
        const password = document.getElementById('password') as HTMLInputElement;

        let isValid = true;

        if (!email.value || !/\S+@\S+\.\S+/.test(email.value)) {
            setEmailError(true);
            setEmailErrorMessage('Please enter a valid email address.');
            isValid = false;
        } else {
            setEmailError(false);
            setEmailErrorMessage('');
        }

        if (!password.value || password.value.length < 6) {
            setPasswordError(true);
            setPasswordErrorMessage('Invalid Password');
            isValid = false;
        } else {
            setPasswordError(false);
            setPasswordErrorMessage('');
        }

        return isValid;
    };

    return (
        <Stack
            direction="row"
            justifyContent="center"
            alignItems="center"
            sx={{ width: 1, height: "100vh", backgroundColor: theme.palette.background.default }}
        >
            <Card variant={'outlined'} sx={{ justifyContent: "space-between", width: "400px", backgroundColor: theme.palette.primary.main }}>
                <Typography
                    component="h1"
                    variant="h4"
                    sx={{ width: '100%', marginBottom: '20px' }}
                >
                    Sign in
                </Typography>
                {errorMessage && (
                    <Card sx={{ backgroundColor: theme.palette.secondary.main, padding: 2, marginBottom: 2 }}>
                        <Typography
                            sx={{
                                color: theme.palette.error.main,
                                fontWeight: 'bold',
                            }}
                        >
                            {errorMessage}
                        </Typography>
                    </Card>
                )}
                <Box
                    component="form"
                    onSubmit={handleSubmit}
                    noValidate
                    sx={{
                        display: 'flex',
                        flexDirection: 'column',
                        width: '100%',
                        gap: 2,
                    }}
                >
                    <FormControl>
                        <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
                            <FormLabel sx={{ color: 'text.primary', margin: 1 }} htmlFor="email">Email</FormLabel>
                        </Box>
                        <TextField
                            error={emailError}
                            helperText={emailErrorMessage}
                            id="email"
                            type="email"
                            name="email"
                            placeholder="your@email.com"
                            autoComplete="email"
                            autoFocus
                            required
                            fullWidth
                            variant="outlined"
                            color={emailError ? 'error' : 'primary'}
                            sx={{ ariaLabel: 'email', border: 'none' }}
                        />
                    </FormControl>
                    <FormControl>
                        <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
                            <FormLabel sx={{ color: 'text.primary', margin: 1 }} htmlFor="password">Password</FormLabel>
                        </Box>
                        <TextField
                            error={passwordError}
                            helperText={passwordErrorMessage}
                            name="password"
                            placeholder="••••••"
                            type="password"
                            id="password"
                            autoComplete="current-password"
                            required
                            fullWidth
                            variant="outlined"
                            color={passwordError ? 'error' : 'primary'}
                        />
                    </FormControl>
                    {/*<FormControlLabel*/}
                    {/*    control={<Checkbox value="remember" color="primary"/>}*/}
                    {/*    label="Remember me"*/}
                    {/*/>*/}
                    {/*<ForgotPassword open={open} handleClose={handleClose}/>*/}
                    {isLoading ? (
                        <LoadingWheel />
                    ) : (
                        <Button
                            type="submit"
                            fullWidth
                            variant="contained"
                            onClick={validateInputs}
                            sx={{ color: theme.palette.text.secondary, fontWeight: 'bold' }}
                        >
                            Sign in
                        </Button>
                    )}
                    <Typography sx={{ textAlign: 'center' }}>
                        Don&apos;t have an account?{' '}
                        <span>
                            <Link to={'/'} style={{ color: theme.palette.text.secondary, textDecoration: 'none' }}>Sign up</Link>
                        </span>
                    </Typography>

                    <Typography sx={{ textAlign: 'center' }}>
                        Forgot your password?{' '}
                        <span>
                            <Link to={'/password/reset/request'} style={{ color: theme.palette.text.secondary, textDecoration: 'none' }}>Reset it here</Link>
                        </span>
                    </Typography>
                </Box>
            </Card>
        </Stack>
    );
}