import './App.css';
import Login from "./pages/login";
import Logout from "./pages/logout";
import Register from "./pages/register";
import PasswordReset from "./pages/password_reset";
import PasswordResetRequest from "./pages/password_reset_request";
import { BrowserRouter, Routes, Route, Navigate } from 'react-router-dom';
import PrivateRoute from "./components/auth/PrivateRoute";
import FourOhFour from "./pages/404";
import { useAuth } from "./components/auth/AuthProvider";
import Home from "./components/home/home";
import Explorer from "./components/explorer/Explorer";
import Profile from "./components/profile/profile";
import Settings from "./components/settings/settings";
import Meeting from "./components/meeting/meeting";
import MeetingView from "./components/meeting/meeting/meeting_view";
import Recruitment from "./components/recruitment/recruitment";
import JobOpening from "./components/recruitment/job_openings/job_openings";
import JobDescription from "./components/recruitment/job_descriptions/job_descriptions";
import { default as JobDescriptionEdit } from "./components/recruitment/job_descriptions/edit";
import { default as JobOpeningEdit } from "./components/recruitment/job_openings/edit";
import TalentPool from "./components/recruitment/talent_pool/talent_pool";
import Uploader from "./components/uploader";
import * as React from "react";
import { useState } from "react";
import { ThemeProvider, CssBaseline } from '@mui/material'
import { theme } from 'src/components/theme/theme';
import RegularPage from "src/pages/RegularPage";
import AccountSettings from './components/settings/settings/account_settings';

function App() {
    const auth = useAuth()
    const [navSelection, setNavSelection] = useState<string>('search');


    return (
        <div className="App">
            <ThemeProvider theme={theme}>
                <CssBaseline />
                <BrowserRouter>
                    <Routes>
                        <Route path="/home" element={auth.user ? <Navigate to="/" /> : <Home />} />
                        <Route path="/login" element={auth.user ? <Navigate to="/" /> : <Login />} />
                        <Route path="/register" element={auth.user ? <Navigate to="/" /> : <Register />} />
                        <Route path="/password/reset" element={auth.user ? <Navigate to="/" /> : <PasswordReset />} />
                        <Route path="/password/reset/request" element={auth.user ? <Navigate to="/" /> : <PasswordResetRequest />} />
                        <Route element={<PrivateRoute />}>
                            <Route
                                element={<RegularPage navSelection={navSelection}
                                    setNavSelection={setNavSelection} />}>
                                <Route path="/" element={<Explorer />} />
                                <Route path="/logout" element={<Logout />} />
                                <Route path="/profile" element={<Profile />} />
                                <Route path="/meeting" element={<Meeting />} />
                                <Route path="/meeting/:id" element={<MeetingView />} />
                                <Route path="/recruitment" element={<Recruitment />}>
                                    <Route path="job/openings" element={<JobOpening />} />
                                    <Route path="job/opening/create" element={<JobOpeningEdit />} />
                                    <Route path="job/opening/edit/:id" element={<JobOpeningEdit />} />
                                    <Route path="talent" element={<TalentPool />} />
                                    <Route path="job/descriptions" element={<JobDescription />} />
                                    <Route path="job/description/create" element={<JobDescriptionEdit />} />
                                    <Route path="job/description/edit/:id" element={<JobDescriptionEdit />} />
                                </Route>
                                <Route path="/search" element={<Explorer />}>
                                    <Route path="person/:id" element={<Profile />} />
                                </Route>
                                <Route path="/graph/:id" element={<Explorer />} />
                                <Route path="/upload" element={<Uploader />} />
                                <Route path="/settings" element={<Settings />}>
                                    <Route path="account" element={<AccountSettings />} />
                                </Route>
                            </Route>
                        </Route>
                        <Route path="*" element={<FourOhFour />} />
                    </Routes>
                </BrowserRouter>
            </ThemeProvider>
        </div>
    );
}

export default App;
