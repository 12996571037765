import React from 'react';
import {Box, Typography, IconButton} from '@mui/material';
import Content from './Content';
import CloseIcon from '@mui/icons-material/Close';
import {Node} from '../../interfaces/Node';
import {useTheme} from "@mui/material/styles";

interface Props {
    node: Node;
    visible: boolean;
    setVisibility: any;
}

export default function GraphPopup(props: Props) {

    const theme = useTheme();

    let handleClose = () => {
        props.setVisibility(false);
    };

    return (
        <div>
            {props.visible &&
                <Box
                    sx={{
                        position: 'absolute',
                        textAlign: 'left',
                        top: {xs: 10, sm: 20},
                        left: {xs: 0, sm: 20},
                        maxWidth: '40%',
                        bgcolor: 'white',
                        color: theme.palette.secondary.dark,
                        boxShadow: '0px 4px 12px rgba(0, 0, 0, 0.1)',
                        p: 2,
                        borderRadius: 2,
                    }}
                >
                    <IconButton onClick={handleClose} style={{position: 'absolute', right: 10}}>
                        <CloseIcon sx={{color: '#666'}}/>
                    </IconButton>
                    <Content node={props.node}></Content>
                </Box>
            }
        </div>
    );
}

