import {Outlet, Navigate} from 'react-router-dom'
import MainLayout from "src/layouts/main_layout";

interface Props{
    navSelection: string,
    setNavSelection: Function,
}

export default function RegularPage(props:Props) {
    return (
            <MainLayout content={<Outlet/>} navSelection={props.navSelection} setNavSelection={props.setNavSelection}/>
        )

}
