import { useCallback, useState } from "react";
import axios from "axios";
import { useDropzone } from "react-dropzone";
import { Card, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper, Button, Typography } from '@mui/material';
import { useTheme } from "@mui/material/styles";

function Uploader() {
    const [files, setFiles] = useState<File[]>([]); // Manage multiple selected files
    const [uploadProgress, setUploadProgress] = useState<number | null>(null); // Progress state
    const [errorMessage, setErrorMessage] = useState<string | null>(null); // Error state
    const [isUploading, setIsUploading] = useState<boolean>(false); // Loading state
    const [successMessage, setSuccessMessage] = useState<string | null>(null); // Success state
    const theme = useTheme();

    // Handle the drop event
    const onDrop = useCallback((acceptedFiles: File[]) => {
        if (acceptedFiles.length > 0) {
            setFiles(acceptedFiles); // Set all accepted files
            setErrorMessage(null); // Clear any previous errors
            setSuccessMessage(null); // Clear previous success messages
        }
    }, []);

    const { getRootProps, getInputProps, isDragActive } = useDropzone({
        onDrop,
        accept: {
            'application/pdf': ['.pdf'],
            'text/html': ['.html', '.htm'],
            'application/msword': ['.doc'],
            'application/vnd.openxmlformats-officedocument.wordprocessingml.document': ['.docx'],
            'message/rfc822': ['.eml'],
            'image/gif': ['.gif'],
            'image/jpeg': ['.jpg', '.jpeg'],
            'application/json': ['.json'],
            'application/vnd.ms-outlook': ['.msg'],
            'image/png': ['.png'],
            'application/vnd.openxmlformats-officedocument.presentationml.presentation': ['.pptx'],
            'application/rtf': ['.rtf'],
            'text/plain': ['.txt'],
            'image/tiff': ['.tiff', '.tif'],
            'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet': ['.xlsx'],
            'application/vnd.ms-excel': ['.xls'],
        },
        multiple: true, // Allow multiple file uploads
    });

    // Function to handle file upload
    const uploadFiles = async () => {
        console.log('upload starting');
        if (files.length === 0) {
            setErrorMessage("No file selected. Please choose a file first.");
            return;
        }

        const formData = new FormData();
        files.forEach(file => {
            formData.append("files", file); // Append each file to form data
        });

        try {
            setIsUploading(true);
            setErrorMessage(null); // Clear any previous errors

            const response = await axios.post(
                process.env.REACT_APP_API_BASE_URL + 'interaction/upload',
                formData,
                {
                    withCredentials: true,
                    headers: {
                        "Content-Type": "multipart/form-data",
                    },
                    onUploadProgress: (progressEvent) => {
                        if (progressEvent.total) {
                            const percentCompleted = Math.round(
                                (progressEvent.loaded * 100) / progressEvent.total
                            );
                            setUploadProgress(percentCompleted); // Track upload progress
                        }
                    },
                }
            );
            // Handle success
            setSuccessMessage("Files uploaded successfully!");
            setFiles([]); // Clear files after successful upload
            setUploadProgress(null);
        } catch (error: any) {
            setErrorMessage(error?.response?.data?.error || "File upload failed. Please try again.");
        } finally {
            setIsUploading(false);
        }
    };

    return (
        <Card>
            <div
                {...getRootProps()}
                style={{
                    padding: "10px",
                    textAlign: "center",
                    cursor: "pointer"
                }}
            >
                <input {...getInputProps()} />
                {isDragActive ? (
                    <Typography variant="h5">Drop files here...</Typography>
                ) : (
                    <Typography variant="h5">Add files by clicking or dragging files here...</Typography>
                )}
            </div>

            {files.length > 0 && !isUploading && (
                <TableContainer component={Paper} style={{ marginTop: '20px' }}>
                    <Table>
                        <TableHead>
                            <TableRow>
                                <TableCell sx={{ backgroundColor: theme.palette.secondary.main, color: theme.palette.text.secondary, fontSize: '16px', fontWeight: 'bold' }}>
                                    File Name</TableCell>
                                <TableCell sx={{ backgroundColor: theme.palette.secondary.main, color: theme.palette.text.secondary, fontSize: '16px', fontWeight: 'bold' }}>
                                    File Size (MB)</TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {files.map((file) => (
                                <TableRow key={file.name}>
                                    <TableCell>
                                        {file.name}
                                    </TableCell>
                                    <TableCell>{(file.size / (1024 * 1024)).toFixed(2)}</TableCell>
                                </TableRow>
                            ))}
                        </TableBody>
                    </Table>
                </TableContainer>
            )}

            {files.length > 0 && !isUploading && (
                <Button
                    variant="contained"
                    color="primary"
                    style={{
                        marginTop: "20px",
                    }}
                    onClick={uploadFiles}
                    disabled={isUploading}
                >
                    Upload Files
                </Button>
            )}


            {isUploading && (
                <Card sx={{ backgroundColor: theme.palette.secondary.main, padding: 2, marginBottom: 2 }}>
                    <Typography
                        sx={{
                            color: theme.palette.primary.main,
                            fontWeight: 'bold',
                        }}
                    >
                        Uploading, please wait...
                    </Typography>
                </Card>
            )}

            {errorMessage && (
                <Card sx={{ backgroundColor: theme.palette.secondary.main, padding: 2, my: 2 }}>
                    <Typography
                        sx={{
                            color: theme.palette.error.main,
                            fontWeight: 'bold',
                        }}
                    >
                        {errorMessage}
                    </Typography>
                </Card>
            )}

            {successMessage && (
                <Card sx={{ backgroundColor: theme.palette.secondary.main, padding: 2, my: 2 }}>
                    <Typography
                        sx={{
                            color: theme.palette.success.main,
                            fontWeight: 'bold',
                        }}
                    >
                        {successMessage}
                    </Typography>
                </Card>
            )}

        </Card>
    );
}

export default Uploader;
