import { Box, Grid2, Typography } from "@mui/material";
import * as React from "react";
import { Link } from 'react-router-dom';
import { useTheme } from "@mui/material/styles";
import { FaHeart } from "react-icons/fa";
import OrgAtlas from 'src/assets/client_logos/OrgAtlas.png';
import Carlton from 'src/assets/client_logos/Carlton.png';
import Anika from 'src/assets/client_logos/Anika.png';
import Hagstrom from 'src/assets/client_logos/Hagstrom.png';
import Silverstone from 'src/assets/client_logos/Silverstone.png';
import Tattarang from 'src/assets/client_logos/Tattarang.png';
import { useEffect, useState } from "react";


interface NavLinkProps {
    to: string;
    title: string;
    selected: string;
    setSelected: Function;
}

const NavLink = (props: NavLinkProps) => {
    const theme = useTheme();

    return (
        <Grid2 sx={{ marginLeft: '60px' }}>
            <Link
                to={props.to}
                style={{
                    textDecoration: 'none',
                    cursor: 'pointer',
                    color: props.selected === props.title ? theme.palette.primary.main : theme.palette.text.primary,
                    transition: 'color 0.8s',
                }}
                onMouseEnter={(e) => {
                    e.currentTarget.style.color = theme.palette.primary.main;
                }}
                onMouseLeave={(e) => {
                    e.currentTarget.style.color = props.selected === props.title ? theme.palette.primary.main : theme.palette.text.primary;
                }}
                onClick={() => {
                    props.setSelected(props.title)
                }}
            >
                <Typography variant="h4" fontSize="16pt">
                    {props.title}
                </Typography>
            </Link>
        </Grid2>
    );
};

const PageLink = (props: NavLinkProps) => {
    const theme = useTheme();

    return (
        <Grid2 sx={{ marginLeft: '60px' }}>
            <a
                href={props.to}
                style={{
                    textDecoration: 'none',
                    cursor: 'pointer',
                    color: props.selected === props.title ? theme.palette.primary.main : theme.palette.text.primary,
                    transition: 'color 0.8s',
                }}
                onMouseEnter={(e) => {
                    e.currentTarget.style.color = theme.palette.primary.main;
                }}
                onMouseLeave={(e) => {
                    e.currentTarget.style.color = props.selected === props.title ? theme.palette.primary.main : theme.palette.text.primary;
                }}
                onClick={() => {
                    props.setSelected(props.title)
                }}
            >
                <Typography variant="h4" fontSize="16pt">
                    {props.title}
                </Typography>
            </a>
        </Grid2>
    );
};

export default function Navbar() {
    const [scrolled, setScrolled] = useState(false);
    const [companyLogo, setCompanyLogo] = useState<any>(null);
    const [selected, setSelected] = useState('Home');
    const theme = useTheme();

    useEffect(() => {
        const handleScroll = () => {
            setScrolled(window.scrollY > 0);
        };

        window.addEventListener('scroll', handleScroll);
        return () => {
            window.removeEventListener('scroll', handleScroll);
        };
    }, []);

    useEffect(() => {
        const url = new URL(window.location.href);
        const hostnameParts = url.hostname.split('.');
        const subdomain = hostnameParts.length > 2 ? hostnameParts[0] : null;

        if (subdomain === 'carlton') {
            setCompanyLogo(Carlton)
        } else if (subdomain === 'anika') {
            setCompanyLogo(Anika)
        } else if (subdomain === 'hagstrom') {
            setCompanyLogo(Hagstrom)
        } else if (subdomain === 'silverstone') {
            setCompanyLogo(Silverstone)
        } else if (subdomain === 'tattarang') {
            setCompanyLogo(Tattarang)
        }
    }, []);

    return (
        <Grid2
            container
            sx={{
                padding: '20px',
                alignItems: 'center',
                position: 'sticky',
                top: 0,
                backgroundColor: theme.palette.background.default,
                boxShadow: scrolled ? '0px 4px 10px rgba(0, 0, 0, 0.1)' : 'none',
                zIndex: 1000,
            }}
        >
            <Grid2 size={1}></Grid2>
            <Grid2 size={3}>
                <Box
                    sx={{
                        height: '80px',
                        maxWidth: '60%',
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'space-between',
                        padding: '8px',
                    }}
                >
                    <img src={OrgAtlas} style={{ height: '70px' }} alt="OrgAtlas" />
                    {companyLogo && (
                        <>
                            <FaHeart color={theme.palette.error.main} size={30} />
                            <img src={companyLogo} style={{ height: '60px' }} alt="Company Logo" />
                        </>
                    )}
                </Box>
            </Grid2>
            <Grid2 size={7} container sx={{ justifyContent: 'flex-end' }}>
                <NavLink to={'/'} title={'Home'} selected={selected} setSelected={setSelected} />
                <PageLink to={'/home#mission'} title={'Mission'} selected={selected} setSelected={setSelected} ></PageLink>
                <NavLink to={'/home#tutorials'} title={'Tutorials'} selected={selected} setSelected={setSelected} />
                <PageLink to={'/home#privacy'} title={'Privacy'} selected={selected} setSelected={setSelected} />
                <NavLink to={'/login'} title={'Login'} selected={selected} setSelected={setSelected} />
            </Grid2>
            <Grid2 size={1}></Grid2>
        </Grid2>
    )
}