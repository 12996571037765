import { Grid2, Typography, Paper, Box } from "@mui/material";
import Navbar from "./navbar";
import { useState } from "react";
import AccountSettings from "./settings/account_settings";
import { Outlet } from "react-router-dom";
interface Setting {
    title: string;
    component: React.ReactNode;
}

export default function Settings() {
    const [navSelection, setNavSelection] = useState<string>("account_settings");

    return (
        <Grid2 container>
            <Grid2 size={3}>
                <Navbar navSelection={navSelection} setNavSelection={setNavSelection} />


            </Grid2>
            <Grid2 size={9}>
                <Box px={2}>
                    <Outlet />
                </Box>
            </Grid2>

        </Grid2>
    );
}