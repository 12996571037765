import {useEffect, useState} from "react";
import axios from "axios";
import * as React from 'react';
import {
    Grid2,
    Typography,
    Card,
    Input
} from '@mui/material';
import {JobDescriptionInterface} from "src/components/recruitment/job_descriptions/job_descriptions";
import Editor from "src/components/editor/editor";
import {useTheme} from "@mui/material/styles";
import {useParams} from "react-router-dom";
import {FaSave} from "react-icons/fa";


export default function Edit() {
    const [job_description, setJobDescription] = useState<JobDescriptionInterface>();
    const [initial_job_description, setInitialJobDescription] = useState<JobDescriptionInterface>()
    const [saveIconColor, setSaveIconColor] = useState<string>('white')

    const params = useParams();
    const theme = useTheme();

    useEffect(() => {
        console.log('resetting')
        if (params.id) {
            axios.post(process.env.REACT_APP_API_BASE_URL + 'recruitment/job/description',
                {'id': params.id},
                {
                    withCredentials: true
                }
            ).then(response => {
                setJobDescription(response.data.job_description as JobDescriptionInterface);
                setInitialJobDescription(response.data.job_description.clone() as JobDescriptionInterface);
            }).catch(error => {
                console.error(error);
            });
        } else {
            setJobDescription({
                'title': '',
                'description': ''
            });
            setInitialJobDescription({
                'title': '',
                'description': ''
            });
        }
    }, [params.id]);

    function save() {
        axios.post(process.env.REACT_APP_API_BASE_URL + 'recruitment/job/description/save',
        {
                'id': job_description?.id,
                'title': job_description?.title,
                'description': job_description?.description,
            },
    {
                withCredentials: true
            }
        ).then(response => {
            setSaveIconColor('green')
        }).catch(error => {
            console.error(error);
        });
    }

    function checkSaved() {
        if ((job_description?.title === initial_job_description?.title) && (job_description?.description === initial_job_description?.description)) {
            setSaveIconColor('white');
        } else {
            setSaveIconColor('red');
        }
    }

    function updateJobTitle(event: any) {
        if (job_description) {
            job_description.title = event.currentTarget.value;
            checkSaved();
        }
    }

    function updateJobDescription(value: any) {
        if (job_description) {
            job_description.description = value
            checkSaved();
        }
    }

    return (
        <Grid2 size={12}>
            <Card>
                <Grid2 container>
                    <Grid2 size={11}>
                        <Typography variant={'h6'} component={'div'}
                                    sx={{marginBottom: '20px', color: 'secondary'}}>Job Title</Typography>
                    </Grid2>
                    <Grid2 size={1}>
                        <FaSave size={30} color={saveIconColor} onClick={save}/>
                    </Grid2>
                </Grid2>

                <Input
                    fullWidth
                    multiline
                    id="job_title"
                    defaultValue={job_description?.title}
                    disableUnderline
                    onKeyUp={updateJobTitle}
                    sx={{
                        color: theme.palette.text.primary,
                        backgroundColor: theme.palette.secondary.main,
                        padding: "12px",
                        marginBottom: '20px',
                        borderRadius: "8px",
                        border: "1px solid #ccc",
                        gap: "0.5em",
                        alignItems: "center",
                        boxShadow: "0 2px 4px rgba(0, 0, 0, 0.2)",
                    }}
                />
                <Typography variant={'h6'} component={'div'}
                            sx={{marginBottom: '20px', color: 'secondary'}}>Job Description</Typography>
                <div style={{backgroundColor: 'white', borderRadius: '5px', color: 'black'}}>
                    <Editor html={job_description?.description ?? ''} onChange={updateJobDescription}/>
                </div>
            </Card>
        </Grid2>
    )
}