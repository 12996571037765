import * as React from 'react';
import Navbar from "../components/nav/side/Navbar";
import { Box, Grid2, AppBar, Toolbar, Typography } from '@mui/material'
import { useTheme } from "@mui/material/styles";
import { Link } from 'react-router-dom';
import { useAuth } from 'src/components/auth/AuthProvider';

interface Props {
    content: React.ReactNode,
    navSelection: string,
    setNavSelection: Function
}

export default function MainLayout(props: Props) {
    const theme = useTheme();
    const { user } = useAuth();
    const [showPasswordWarning, setShowPasswordWarning] = React.useState(false);

    React.useEffect(() => {
        if (user && !user.password_set) {
            setShowPasswordWarning(true);
        } else {
            setShowPasswordWarning(false);
        }
    }, [user]);

    return (
        <Box sx={{ display: 'flex', height: '100vh' }}>
            <Navbar navSelection={props.navSelection} setNavSelection={props.setNavSelection} />

            {/*Toolbar*/}
            <Box sx={{ flexGrow: 1, display: 'flex', flexDirection: 'column' }}>
                {/* Header */}
                <AppBar position="static" color="primary">
                    <Toolbar>
                        <Typography variant="h6" sx={{ textTransform: 'capitalize' }}>{props.navSelection}</Typography>
                    </Toolbar>
                </AppBar>

                {showPasswordWarning && (
                    <Link to={'/settings/account'} style={{ textDecoration: 'none', color: theme.palette.text.primary }}>
                        <Box sx={{ backgroundColor: theme.palette.error.main, height: 50, display: 'flex', alignItems: 'center' }}>
                            <Typography variant="h5" sx={{ ml: 3 }}>You have not set a password yet. Click here to set one now.</Typography>
                        </Box>
                    </Link>
                )}

                {/*Main Content*/}
                <Box sx={{ p: 2, flexGrow: 1, backgroundColor: 'background.default' }}>
                    {props.content}
                </Box>
            </Box>
        </Box>

    );
}