import {useEffect, useState} from "react";
import axios from "axios";
import * as React from 'react';
import {
    Grid2,
    Typography,
    Card,
    Input,
    Autocomplete,
    TextField
} from '@mui/material';
import {useTheme} from "@mui/material/styles";
import {useParams} from "react-router-dom";
import {FaSave} from "react-icons/fa";
import {JobOpeningInterface} from "src/components/recruitment/job_openings/job_openings_list";
import {JobDescriptionInterface} from "src/components/recruitment/job_descriptions/job_descriptions";

interface JobDescriptionOption {
    label: string;
    value?: string;
}

export default function Edit() {
    const [job_opening, setJobOpening] = useState<JobOpeningInterface>();
    const [selected_job_description_id, setSelectedJobDescriptionId] = useState<number>();
    const [initial_job_opening, setInitialJobOpening] = useState<JobOpeningInterface>()
    const [saveIconColor, setSaveIconColor] = useState<string>('white')
    const [job_description_options, setJobDescriptionOptions] = useState<JobDescriptionOption[]>([{
        label: 'No job descriptions',
    }]);

    const params = useParams();
    const theme = useTheme();

    useEffect(() => {
        if (params.id) {
            axios.post(process.env.REACT_APP_API_BASE_URL + 'recruitment/job/opening',
                {'id': params.id},
                {
                    withCredentials: true
                }
            ).then(response => {
                setJobOpening(response.data.job_opening as JobOpeningInterface);
                setInitialJobOpening(response.data.job_opening.clone() as JobOpeningInterface);
            }).catch(error => {
                console.error(error);
            });
        } else {
            setJobOpening({
                'title': '',
                'job_description': undefined,
                'department': '',
                'salary': '',
            });
            setInitialJobOpening({
                'title': '',
                'job_description': undefined,
                'department': '',
                'salary': '',
            });
        }
    }, [params.id]);

    useEffect(() => {
        setSelectedJobDescriptionId(job_opening?.job_description?.id)
    }, [job_opening]);

    // Get the job description options
    useEffect(() => {
        axios.get(process.env.REACT_APP_API_BASE_URL + 'recruitment/job/descriptions',
            {
                withCredentials: true
            }
        ).then(response => {
            let job_description_options: JobDescriptionOption[] = response.data.job_descriptions.map((job_description: JobDescriptionInterface) => ({
                label: job_description.title,
                value: job_description.id
            }));
            setJobDescriptionOptions(job_description_options);
        }).catch(error => {
            console.error(error);
        });
    }, []);

    function save() {
        axios.post(process.env.REACT_APP_API_BASE_URL + 'recruitment/job/opening/save',
            {
                'id': job_opening?.id,
                'title': job_opening?.title,
                'job_description': selected_job_description_id,
                'department': job_opening?.department,
                'salary': job_opening?.salary,
            },
            {
                withCredentials: true
            }
        ).then(response => {
            setSaveIconColor('green')
        }).catch(error => {
            console.error(error);
        });
    }

    function checkSaved() {
        console.log(job_opening?.job_description);
        if ((job_opening?.title === initial_job_opening?.title)
            && (job_opening?.job_description === initial_job_opening?.job_description)
            && (job_opening?.department === initial_job_opening?.department)
            && (job_opening?.salary === initial_job_opening?.salary)
        ) {
            setSaveIconColor('white');
        } else {
            setSaveIconColor('red');
        }
    }

    function updateTitle(event: any) {
        if (job_opening) {
            job_opening.title = event.currentTarget.value;
            checkSaved();
        }
    }

    function updateJobDescription(event: any, newValue: any) {
        if (job_opening) {
            setSelectedJobDescriptionId(newValue.value);
            checkSaved();
        }
    }

    function updateDepartment(event: any) {
        if (job_opening) {
            job_opening.department = event.currentTarget.value;
            checkSaved();
        }
    }

    function updateSalary(event: any) {
        if (job_opening) {
            job_opening.salary = event.currentTarget.value;
            checkSaved();
        }
    }

    return (
        <Grid2 size={12}>
            <Card>
                <Grid2 container>
                    <Grid2 size={11}>
                        <Typography variant={'h6'} component={'div'}
                                    sx={{marginBottom: '20px', color: 'secondary'}}>Job Title</Typography>
                    </Grid2>
                    <Grid2 size={1}>
                        <FaSave size={30} color={saveIconColor} onClick={save}/>
                    </Grid2>
                </Grid2>

                <Input
                    fullWidth
                    multiline
                    id="title"
                    defaultValue={job_opening?.title}
                    disableUnderline
                    onKeyUp={updateTitle}
                    sx={{
                        color: theme.palette.text.primary,
                        backgroundColor: theme.palette.secondary.main,
                        padding: "12px",
                        marginBottom: '20px',
                        borderRadius: "8px",
                        border: "1px solid #ccc",
                        gap: "0.5em",
                        alignItems: "center",
                        boxShadow: "0 2px 4px rgba(0, 0, 0, 0.2)",
                    }}
                />

                <Typography variant={'h6'} component={'div'}
                            sx={{marginBottom: '20px', color: 'secondary'}}>Job Description</Typography>

                <Autocomplete
                    fullWidth
                    disablePortal
                    id="job_description"
                    onChange={updateJobDescription}
                    options={job_description_options}
                    getOptionDisabled={(option) =>
                        option.label === 'No job descriptions' && !option.value
                    }
                    renderInput={(params) => <TextField
                        {...params}
                        placeholder={job_opening?.job_description?.title}
                        sx={{
                            "& input::placeholder": {
                                color: "black",
                                opacity: 1,
                            },
                        }}
                    />}
                    sx={{
                        color: theme.palette.text.primary,
                        backgroundColor: theme.palette.secondary.main,
                        marginBottom: '20px',
                        borderRadius: "8px",
                        border: "1px solid #ccc",
                        gap: "0.5em",
                        alignItems: "center",
                        boxShadow: "0 2px 4px rgba(0, 0, 0, 0.2)",
                        "& .MuiOutlinedInput-root": {
                            "&:hover fieldset": {
                                borderColor: "transparent",
                            },
                            "&.Mui-focused fieldset": {
                                borderColor: "transparent",
                                boxShadow: "none",
                            },
                        },
                    }}
                    ListboxProps={{
                        style: {
                            backgroundColor: 'white',
                            color: 'black',
                        }
                    }}
                />


                <Typography variant={'h6'} component={'div'}
                            sx={{marginBottom: '20px', color: 'secondary'}}>Department</Typography>
                <Input
                    fullWidth
                    multiline
                    id="department"
                    defaultValue={job_opening?.department}
                    disableUnderline
                    onKeyUp={updateDepartment}
                    sx={{
                        color: theme.palette.text.primary,
                        backgroundColor: theme.palette.secondary.main,
                        padding: "12px",
                        marginBottom: '20px',
                        borderRadius: "8px",
                        border: "1px solid #ccc",
                        gap: "0.5em",
                        alignItems: "center",
                        boxShadow: "0 2px 4px rgba(0, 0, 0, 0.2)",
                    }}
                />

                <Typography variant={'h6'} component={'div'}
                            sx={{marginBottom: '20px', color: 'secondary'}}>Salary</Typography>
                <Input
                    fullWidth
                    multiline
                    id="salary"
                    defaultValue={job_opening?.salary}
                    disableUnderline
                    onKeyUp={updateSalary}
                    sx={{
                        color: theme.palette.text.primary,
                        backgroundColor: theme.palette.secondary.main,
                        padding: "12px",
                        marginBottom: '20px',
                        borderRadius: "8px",
                        border: "1px solid #ccc",
                        gap: "0.5em",
                        alignItems: "center",
                        boxShadow: "0 2px 4px rgba(0, 0, 0, 0.2)",
                    }}
                />
            </Card>
        </Grid2>
    )
}