import {AttendeeInterface} from "src/components/meeting/meeting";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";

interface Props {
    attendee: AttendeeInterface
}

export default function AttendeeItem(props: Props) {
    return (
        <Box
            component="li"
            display="flex"
            justifyContent="space-between"
            alignItems="center"
        >
            <Box>
                <Typography variant="body1" component="span" sx={{color: 'white'}}>
                    {props.attendee.email!}
                </Typography>

            </Box>
            <Box display="flex" alignItems="center">
                <Button>
                    Edit
                </Button>
                <Button>
                    View
                </Button>
            </Box>
        </Box>
    );
}
