import axios from "axios";
import {useNavigate, useParams} from "react-router-dom";
import Agenda from "./agenda/agenda";
import Minutes from "./minutes/minutes";
import Attendees from "./attendees/attendees";
import {useEffect, useState} from "react";
import {MeetingInterface, AttendeeInterface, AgendaInterface, MinutesInterface} from "@/components/meeting/meeting";
import * as React from 'react';
import {Box, Card} from '@mui/material';
import Grid from '@mui/material/Grid2';
import Typography from "@mui/material/Typography";
import LoadingWheel from "src/components/theme/loading";
import {useTheme} from "@mui/material/styles";

export default function MeetingView() {
    const params = useParams();
    const theme = useTheme();
    const [meeting, setMeeting] = useState<MeetingInterface>()
    const [attendees, setAttendees] = useState<AttendeeInterface[]>([])
    const [agendas, setAgendas] = useState<AgendaInterface[]>([])
    const [minutes, setMinutes] = useState<MinutesInterface[]>([])


    useEffect(() => {
        axios.post(process.env.REACT_APP_API_BASE_URL + 'meeting/details',
            {'id': params.id},
            {
                withCredentials: true
            }
        ).then(response => {
            setMeeting(response.data.meeting as MeetingInterface);
            setAttendees(response.data.attendees as AttendeeInterface[]);
            setAgendas(response.data.agendas as AgendaInterface[]);
            setMinutes(response.data.minutes as MinutesInterface[]);
            console.log(response.data);
        }).catch(error => {
            console.error(error);
        });
    }, []);

    return (

        <Box>
            <Grid container spacing={2}>
                {/*Content*/}
                <Grid size={8}>
                    <Card sx={{backgroundColor: theme.palette.secondary.main, color: theme.palette.text.secondary}}>
                        <Typography variant="h5" component="div">{meeting ? meeting.title :
                            <LoadingWheel/>}</Typography>
                    </Card>

                    <Grid container>
                        <Grid size={3}>
                            <Card variant={'outlined'} sx={{marginTop: '20px', marginRight: '20px'}}>
                                {meeting ?
                                    <div>
                                        <div>{meeting.location ?? "No Location"}</div>
                                        <div>{meeting.start_datetime ?? "No Time"}</div>
                                        <div>{meeting.end_datetime}</div>
                                    </div>
                                    : <LoadingWheel/>}
                            </Card>

                        </Grid>
                        <Grid size={9}>
                            <Card sx={{marginTop: '20px'}}>
                                {meeting ?
                                    <div>
                                        <div>{meeting.description ?? "No Description"}</div>
                                    </div>
                                    : <LoadingWheel/>}
                            </Card>
                        </Grid>
                    </Grid>
                </Grid>

                {/*Sidebar*/}
                <Grid size={4}>
                    <Grid size={12}>
                        <Attendees attendees={attendees}/>
                    </Grid>
                    <Grid size={12}>
                        <Agenda agendas={agendas}/>
                    </Grid>
                    <Grid size={12}>
                        <Minutes minutes={minutes}/>
                    </Grid>
                </Grid>
            </Grid>
        </Box>
    );
}