import * as React from 'react';
import axios from "axios";
import { Card, Modal, Typography, TextField, FormControl, Button, Box, Grid2 } from '@mui/material'
import { useTheme } from "@mui/material/styles";
import { FaQuestionCircle } from "react-icons/fa";
import { useEffect, useState } from "react";
import LoadingWheel from "src/components/theme/loading";
import Navbar from './navbar'
import { Link, useLocation } from 'react-router-dom';
import Mission from './mission';
import About from './about';
import Privacy from './privacy';
import Tutorials from './tutorials';

export default function Home() {
    const [introduction, setIntroduction] = useState('');
    const [welcomeText, setWelcomeText] = useState('');
    const [email, setEmail] = useState('');
    const [emailErrorMessage, setEmailErrorMessage] = useState<string | null>(null);
    const [introductionErrorMessage, setIntroductionErrorMessage] = useState<string | null>(null);
    const [submitDisabled, setSubmitDisabled] = useState(false);
    const [introDisplay, setIntroDisplay] = useState(true);
    const [emailDisplay, setEmailDisplay] = useState(false);
    const [completeDisplay, setCompleteDisplay] = useState(false);
    const theme = useTheme();

    const [open, setOpen] = React.useState(false);
    const handleOpen = () => setOpen(true);
    const handleClose = () => setOpen(false);

    const location = useLocation();

    useEffect(() => {
        if (location.hash) {
            const elementId = location.hash.substring(1); // Remove the '#' from the hash
            const element = document.getElementById(elementId);
            if (element) {
                element.scrollIntoView({ behavior: 'smooth' });
            }
        }
    }, [location]);

    const submitIntroduction = () => {
        if (!introduction.trim()) {
            setIntroductionErrorMessage('Please introduce yourself before continuing.');
            return;
        }

        setSubmitDisabled(true);
        axios.post(process.env.REACT_APP_API_BASE_URL + 'signup/init',
            { introduction: introduction }
        ).then(response => {
            setSubmitDisabled(false);
            setWelcomeText(response.data.welcome_prompt);
            setIntroDisplay(false);
            setEmailDisplay(true);
        }).catch(error => {
            console.error(error);
            setSubmitDisabled(false);
        });
    };

    const submitEmail = () => {
        setSubmitDisabled(true);
        if (!email || !/\S+@\S+\.\S+/.test(email)) {
            setEmailErrorMessage('Please enter a valid email address.');
            setSubmitDisabled(false);
        } else {
            setEmailErrorMessage(null);
            setSubmitDisabled(true);
            axios.post(process.env.REACT_APP_API_BASE_URL + 'signup/confirm',
                {
                    email: email,
                    introduction: introduction
                }
            ).then(response => {
                setIntroDisplay(false);
                setEmailDisplay(false);
                setCompleteDisplay(true);
            }).catch(error => {
                console.error(error);
                if (error.response.data.error === "user with this email already exists.") {
                    setEmailErrorMessage("Hmmm... Seems like you already have an account. Click the login button at the top of the page to log in.");
                } else {
                    setEmailErrorMessage(error.response.data.error);
                }
                setSubmitDisabled(false);

            });
        }
    };

    return (
        <Box sx={{ 'backgroundColor': theme.palette.background.default }}>

            <Navbar />
            <Box minHeight={'100vh'}>

                {/*Content*/
                }
                <Grid2 container sx={{
                    justifyContent: "center",
                    alignItems: "center",
                    mt: '100px',
                    color: theme.palette.text.primary
                }}>
                    <Grid2 size={6} sx={{ padding: '20px', textAlign: 'center' }}>
                        <Typography variant="h2" component="h2" sx={{ mb: 4 }}>
                            Welcome to Org<span style={{ color: theme.palette.primary.main }}>Atlas</span>
                        </Typography>
                        <Typography variant="h6" component="h2">
                            Have you ever had the question...
                        </Typography>
                        <Typography variant="h4" component="h2" fontSize={'20pt'}
                            sx={{ mb: 2, color: theme.palette.primary.dark }}>
                            "Who do I speak to about this?"
                        </Typography>
                        <Typography variant="h6" component="h2" sx={{ maxWidth: '70%', margin: '0 auto' }}>
                            OrgAtlas creates a knowledge map for
                            your organisation, so that you can find the resources you need when you need them.
                        </Typography>
                    </Grid2>
                    <Grid2 size={6} sx={{ textAlign: 'center' }}>

                        {/*Introduction*/}
                        {introDisplay && <Card sx={{
                            maxWidth: '70%',
                            margin: '0 auto',
                            position: 'relative',
                            backgroundColor: theme.palette.secondary.main
                        }}>
                            <FaQuestionCircle
                                onClick={handleOpen}
                                color={theme.palette.primary.main}
                                size={30}
                                style={{
                                    position: 'absolute',
                                    top: 8,
                                    right: 8,
                                    zIndex: 1
                                }}
                            />

                            <Typography variant="h5" component="h2"
                                sx={{ marginBottom: 2, color: introductionErrorMessage ? theme.palette.error.main : theme.palette.text.secondary }}>
                                {introductionErrorMessage ? introductionErrorMessage : 'Nice to meet you, start by introducing yourself...'}
                            </Typography>

                            <FormControl sx={{ width: '100%', marginBottom: 8 }}>
                                <TextField
                                    value={introduction}
                                    onChange={(e) => setIntroduction(e.target.value)}
                                    multiline
                                    rows={12}
                                    placeholder="Include your name, job title, your key skills and responsibilities. Then share a bit about what an average day looks like at work?"
                                    type="text"
                                    id="introduction"
                                    required
                                    fullWidth
                                    variant="outlined"
                                    sx={{
                                        backgroundColor: theme.palette.background.default,
                                        color: theme.palette.text.primary,
                                        '& .MuiInputBase-input::placeholder': {
                                            color: theme.palette.text.primary,
                                            opacity: 1
                                        }
                                    }}
                                />
                            </FormControl>

                            {submitDisabled ? <LoadingWheel></LoadingWheel>
                                : <Button
                                    onClick={submitIntroduction}
                                    variant="contained"
                                    color="primary"
                                    component="a"
                                    sx={{
                                        position: 'absolute',
                                        bottom: 20,
                                        right: 20,
                                        zIndex: 1,
                                        backgroundColor: theme.palette.primary.main,
                                    }}>
                                    <Typography variant="h5" component="h2"
                                        sx={{ color: theme.palette.text.secondary, padding: 4 }}>
                                        Next
                                    </Typography>
                                </Button>}

                        </Card>}

                        {/*Email Confirm*/}
                        {emailDisplay && <Card sx={{
                            maxWidth: '70%',
                            margin: '0 auto',
                            position: 'relative',
                            backgroundColor: theme.palette.secondary.main
                        }}>
                            <Typography variant="h6" component="h2" sx={{
                                marginBottom: 2,
                                color: emailErrorMessage ? theme.palette.error.main : theme.palette.text.secondary
                            }}>
                                {emailErrorMessage ? emailErrorMessage : welcomeText}
                            </Typography>

                            <FormControl sx={{ width: '100%', marginBottom: 8 }}>
                                <TextField
                                    value={email}
                                    onChange={(e) => setEmail(e.target.value)}
                                    rows={1}
                                    placeholder="Your email..."
                                    type="email"
                                    id="email"
                                    required
                                    fullWidth
                                    variant="outlined"
                                    sx={{
                                        backgroundColor: theme.palette.background.default,
                                        color: theme.palette.text.primary,
                                        '& .MuiInputBase-input::placeholder': {
                                            color: theme.palette.text.primary,
                                            opacity: 1
                                        }
                                    }}
                                />
                            </FormControl>

                            {submitDisabled ? <LoadingWheel></LoadingWheel>
                                : <Button
                                    onClick={submitEmail}
                                    variant="contained"
                                    color="primary"
                                    component="a"
                                    sx={{
                                        position: 'absolute',
                                        bottom: 20,
                                        right: 20,
                                        zIndex: 1,
                                        backgroundColor: theme.palette.primary.main
                                    }}>
                                    <Typography variant="h5" component="h2"
                                        sx={{ color: theme.palette.text.secondary, padding: 4 }}>
                                        Done!
                                    </Typography>
                                </Button>}

                        </Card>}

                        {/*Completed*/}
                        {completeDisplay && <Card sx={{
                            maxWidth: '70%',
                            margin: '0 auto',
                            position: 'relative',
                            backgroundColor: theme.palette.secondary.main
                        }}>
                            <Typography variant="h3" component="h2"
                                sx={{ marginBottom: 2, color: theme.palette.text.secondary }}>
                                Thanks for joining us!
                            </Typography>
                            <Typography variant="h5" component="h2"
                                sx={{ marginBottom: 2, color: theme.palette.text.secondary }}>
                                Please check your email to complete your registration
                            </Typography>
                        </Card>}

                        {/*help modal*/}
                        <Modal
                            open={open}
                            onClose={handleClose}
                            aria-labelledby="modal-modal-title"
                            aria-describedby="modal-modal-description"
                        >
                            <Box sx={{
                                position: 'absolute',
                                top: '50%',
                                left: '50%',
                                transform: 'translate(-50%, -50%)',
                                width: 400,
                                bgcolor: 'background.default',
                                boxShadow: 24,
                                borderRadius: 5,
                                p: 4,
                            }}>
                                <Typography id="modal-modal-title" variant="h6" component="h2">
                                    Here's an example of an introduction to get you started!
                                </Typography>
                                <Typography id="modal-modal-description" sx={{ mt: 2 }}>
                                    My name is John Smith, and I'm an accountant. I've worked here for 6 years now. I
                                    have
                                    great analytical skills and regularly prepare and review financial statements for
                                    our
                                    accounts department. I have recently been working on a new accounting system
                                    implementation. Nice to meet you OrgAtlas!
                                </Typography>
                            </Box>
                        </Modal>

                        <Typography variant="body1" component="h2" sx={{
                            maxWidth: '70%',
                            margin: '0 auto',
                            marginTop: 2,
                            color: theme.palette.text.primary
                        }}>
                            Have we met before? <Link to={'/login'}
                                style={{
                                    color: theme.palette.primary.main,
                                    textDecoration: 'none'
                                }}>Login
                                here</Link>
                        </Typography>
                    </Grid2>
                </Grid2>
            </Box>
            <Mission />
            <About />
            <Privacy />
            <Tutorials />
        </Box>
    );
}