import {Button, Card, Typography, Grid2} from "@mui/material";
import JobDescriptionsList from "./job_descriptions_list";
import {useNavigate} from "react-router-dom";
import {FaPlus} from "react-icons/fa";

export interface JobDescriptionInterface {
    id?: number
    title: string
    description: string
    created?: Date
}


export default function JobDescriptions() {
    const navigate = useNavigate();
    return (
        <Grid2 size={12}>
            <Card sx={{marginBottom: '20px', display: 'flex', justifyContent: 'space-between'}}>
                <Typography variant="h5" component="div">Job Descriptions</Typography>
                <Button onClick={() => navigate('/recruitment/job/description/create')} sx={{padding: 0, color: 'primary.dark', backgroundColor: 'white'}}><FaPlus size={20}/></Button>
            </Card>
            <JobDescriptionsList/>
        </Grid2>
    )

}
