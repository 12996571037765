import React, {createContext, useState, useEffect, useContext, ReactNode} from 'react';
import axios from 'axios';
import {TailSpin} from "react-loader-spinner";
import {useTheme} from "@mui/material/styles";

interface AuthContextType {
    user: any | null;
    loading: boolean;
    login: (user: any) => void;
    logout: () => void;
}

// Create the context with a default value of null
const AuthContext = createContext<AuthContextType | undefined>(undefined);

// AuthProvider component
export const AuthProvider = ({children}: { children: ReactNode }) => {
    const [user, setUser] = useState<any | null>(null);
    const [loading, setLoading] = useState(true);
    const theme = useTheme()

    // Check if the user is logged in by calling the /auth/user endpoint
    useEffect(() => {
        const checkAuth = async () => {
            try {
                const response = await axios.get(process.env.REACT_APP_API_BASE_URL + 'auth/user', {
                    withCredentials: true,
                });
                setUser(response.data.user); // Set the user if authenticated
            } catch (error) {
                console.error('User not authenticated:', error);
                setUser(null); // Set the user to null if not authenticated
            } finally {
                setLoading(false); // Finish loading once the check is complete
            }
        };

        checkAuth();
    }, []);

    // Login function to set the user
    const login = (user: any) => {
        setUser(user);
    };

    const logout = () => {
        axios.get(process.env.REACT_APP_API_BASE_URL + 'auth/logout',
            {
                withCredentials: true
            }
        ).then(response => {
            setUser(null);
            console.log('logged out successfully');
        }).catch(error => {
            console.error(error);
        });
    };

    return (
        <AuthContext.Provider value={{user, loading, login, logout}}>
            {!loading ? children :
                <div style={{
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    height: '100vh'
                }}>
                    <TailSpin
                        visible={true}
                        height="40"
                        width="40"
                        color={theme.palette.primary.main}
                        ariaLabel="tail-spin-loading"
                        radius="1"
                        wrapperStyle={{}}
                        wrapperClass=""
                    />
                </div>
            }
        </AuthContext.Provider>
    );
};

// Custom hook to use the AuthContext
export const useAuth = () => {
    const context = useContext(AuthContext);
    if (context === undefined) {
        throw new Error('useAuth must be used within an AuthProvider');
    }
    return context;
};
