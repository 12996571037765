import axios from "axios";
import {useEffect, useState} from "react";
import {Card, Typography, Box} from '@mui/material'
import {useTheme} from "@mui/material/styles";

interface ProfileTitleProps {
    title: string
}

interface ProfileItemProps {
    item: string
}

function ProfileItem(props: ProfileItemProps) {
    return (
        <div className={'profile-details-item'}>
            {props.item}
        </div>
    )
}

interface Props {
    id: string | undefined
}

export default function ProfileDetails(props: Props) {
    const [name, setName] = useState('No Name Found');
    const [email, setEmail] = useState('No Email Found');
    const [phone, setPhone] = useState(null);
    const [role, setRole] = useState(null);

    useEffect(() => {
        if (props.id) {
            axios.post(process.env.REACT_APP_API_BASE_URL + 'object/details',
                {'id': props.id},
                {
                    withCredentials: true
                }
            ).then(response => {
                setName(response.data.first_name + ' ' + response.data.last_name);
                setEmail(response.data.email);
                setPhone(response.data.phone);
                setRole(response.data.role);
            }).catch(error => {
                console.error(error);
            });
        } else {
            axios.post(process.env.REACT_APP_API_BASE_URL + 'user/details',
                {'object_id': props.id},
                {
                    withCredentials: true
                }
            ).then(response => {
                setName(response.data.first_name + ' ' + response.data.last_name);
                setEmail(response.data.email);
                setPhone(response.data.phone);
                setRole(response.data.role);
            }).catch(error => {
                console.error(error);
            });
        }

    }, []);

    const theme = useTheme();

    return (
        <Card variant={'outlined'}>
            <Typography variant='h5'>{name}</Typography>
            <Box height={'20px'}/>
            {email && <Typography variant='h6'> {email}</Typography>}
            {phone && <Typography variant='h6'> {phone}</Typography>}
            {role && <Typography variant='h6'> {role}</Typography>}
        </Card>
    )
}