import React, { useEffect, useState } from "react";
import axios from "axios";
import {
    Card,
    Tabs,
    Tab,
    Box,
    Table,
    TableHead,
    TableBody,
    TableRow,
    TableCell,
    TableContainer
} from '@mui/material'
import { useTheme } from "@mui/material/styles";


interface Relationships {
    [key: string]: any[];
}

interface TabPanelProps {
    children?: React.ReactNode;
    index: string;
    value: string;
}

function CustomTabPanel(props: TabPanelProps) {
    const { children, value, index, ...other } = props;

    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={index}
            {...other}
        >
            {value === index && <Box>{children}</Box>}
        </div>
    );
}

interface ContentTabProps {
    section: string,
    relationships: Relationships,
}

function ContentTab(props: ContentTabProps) {
    const theme = useTheme();

    const formatHeader = (key: string) => {
        return key.replace(/_/g, ' ').replace(/\b\w/g, c => c.toUpperCase());
    };

    const hasData = (key: string) => {
        return props.relationships[props.section].some(item =>
            item.type === props.section && item[key] !== null && item[key] !== undefined && item[key] !== ''
        );
    };

    // Get valid keys (excluding 'type' and empty columns)
    const validKeys = Object.keys(props.relationships[props.section][0])
        .slice(1)  // remove type
        .filter(hasData);

    // Filter items and remove duplicates
    const filteredItems = props.relationships[props.section]
        .filter(item => item.type === props.section)
        .filter((item, index, self) =>
            index === self.findIndex((t) =>
                validKeys.every(key => t[key] === item[key])
            )
        );

    return (
        <CustomTabPanel value={props.section} index={props.section}>
            <TableContainer sx={{ maxWidth: '100%', overflowX: 'auto' }}>
                <Table>
                    <TableHead>
                        <TableRow>
                            {validKeys.map((key: string, index: number) => (
                                <TableCell sx={{ backgroundColor: theme.palette.secondary.main, color: theme.palette.text.secondary, fontSize: '16px', fontWeight: 'bold' }} key={index}>
                                    {formatHeader(key)}
                                </TableCell>
                            ))}
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {filteredItems.map((item: any, index: number) => (
                            <TableRow key={index}>
                                {validKeys.map((key: string, i: number) => (
                                    <TableCell key={i}>
                                        {item[key] !== undefined ? item[key] : ''}
                                    </TableCell>
                                ))}
                            </TableRow>
                        ))}
                    </TableBody>
                </Table>
            </TableContainer>
        </CustomTabPanel>
    )
}

interface Props {
    id: string | undefined
}

export default function ProfileContent(props: Props) {
    const [relationships, setRelationships] = useState<Relationships | null>(null);
    const [tab, setTab] = React.useState(0);
    const theme = useTheme();

    const handleChange = (event: React.SyntheticEvent, newValue: number) => {
        setTab(newValue);
    };

    useEffect(() => {
        axios.post(process.env.REACT_APP_API_BASE_URL + 'user/relationships',
            { 'object_id': props.id },
            {
                withCredentials: true
            }
        ).then(response => {
            setRelationships(response.data as Relationships);
        }).catch(error => {
            console.error(error);
        });

    }, []);
    const getTabLabel = (section: string) => {
        if (section === 'person') return 'People';
        if (section === 'actionitem') return 'Actions';
        return section.charAt(0).toUpperCase() + section.slice(1) + 's';
    };

    return (
        <Card sx={{ overflow: 'hidden', width: '100%', p: 0 }}>
            {!relationships && (<div>Nothing to see here...</div>)}

            {/*TABS*/}
            <Box sx={{ maxWidth: '100%' }}>
                <Tabs
                    value={tab}
                    onChange={handleChange}
                    variant="scrollable"
                    scrollButtons="auto"
                    TabIndicatorProps={{
                        style: {
                            display: 'none'
                        }
                    }}
                >
                    {relationships && Object.keys(relationships).map((section: string, index: number) => (
                        <Tab
                            key={index}
                            label={getTabLabel(section.toLowerCase())}
                            sx={{
                                backgroundColor: tab === index ? theme.palette.primary.main : 'inherit',
                                color: tab === index ? theme.palette.text.primary : 'inherit'
                            }}
                        />
                    ))}
                </Tabs>
            </Box>

            {/*CONTENT*/}
            {relationships && Object.keys(relationships).map((section: string, index: number) => (
                <div key={index} style={{ display: tab === index ? 'block' : 'none' }}>
                    <ContentTab section={section} relationships={relationships} />
                </div>
            ))}
        </Card>
    )
}