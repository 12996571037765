import React, { ReactNode } from 'react';
import { Link } from "react-router-dom";
import { ListItem, ListItemButton, ListItemText, ListItemIcon } from '@mui/material'
import { useTheme } from "@mui/material/styles";
import Typography from "@mui/material/Typography";

interface Props {
    navItem: string
    image: ReactNode
    navSelection: string
    setNavSelection: Function
}

export default function NavItem(props: Props) {
    const theme = useTheme();

    return (
        <Link to={`/${props.navItem}`}
            onClick={() => props.setNavSelection(props.navItem)} style={{ textDecoration: 'none' }}>
            <ListItem disablePadding>
                <ListItemButton
                    sx={{
                        backgroundColor: props.navSelection === props.navItem ? theme.palette.background.default : theme.palette.secondary.main,
                        '&:hover': {
                            backgroundColor: theme.palette.secondary.light,
                        },
                    }}
                >
                    <ListItemIcon
                        sx={{
                            color: props.navSelection === props.navItem ? theme.palette.text.primary : theme.palette.text.secondary
                        }}
                    >
                        {props.image}
                    </ListItemIcon>
                    <Typography variant="h6" component="div" color={props.navSelection === props.navItem ? theme.palette.text.primary : theme.palette.text.secondary} fontWeight={400}>
                        {props.navItem.charAt(0).toUpperCase() + props.navItem.slice(1)}
                    </Typography>
                </ListItemButton>
            </ListItem>
        </Link>
    );
}