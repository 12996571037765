import {Input} from "@mui/material";
import {FaMagnifyingGlass} from "react-icons/fa6";
import {useTheme} from "@mui/material/styles";
import {useState} from "react";

interface Props {
    searchHandler: Function
}

export default function SearchBar(props: Props) {
    const theme = useTheme();
    const [searchTerm, setSearchTerm] = useState<string>("");

    return (
        <Input
            value={searchTerm}
            onChange={(e) => setSearchTerm(e.target.value)}
            onKeyDown={(e) => {
                if (e.key === "Enter") {
                    e.preventDefault();
                    props.searchHandler(searchTerm);
                }
            }}
            fullWidth
            multiline
            id="searchTerm"
            placeholder="Search for anything..."
            startAdornment={<FaMagnifyingGlass style={{cursor: 'pointer'}} onClick={(e) => {
                e.preventDefault();
                props.searchHandler(searchTerm);
            }}/>}
            disableUnderline
            sx={{
                color: theme.palette.text.primary,
                padding: "12px",
                borderRadius: "8px",
                border: "1px solid #ccc",
                gap: "0.5em",
                alignItems: "center",
                boxShadow: "0 2px 4px rgba(0, 0, 0, 0.2)",
            }}
        />
    );
}