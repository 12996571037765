import {FaSearch} from "react-icons/fa";
import React from "react";
import {useNavigate} from "react-router-dom";
import {FaCircleNodes} from "react-icons/fa6";
import {Card, Grid2} from '@mui/material'
import {useTheme} from "@mui/material/styles";

interface Result {
    object_id: number,
    title: string,
    type: string,
    short_description: string,
}

interface Props {
    result: Result | null,
    selectHandler: Function
}


export default function SearchResult(props: Props) {
    const theme = useTheme();
    const navigate = useNavigate();

    return (
        <Card
            onClick={() => props.selectHandler(props.result?.object_id)}
            sx={{marginBottom: '20px'}}
        >
            <div style={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
                fontSize: "20px",
                fontWeight: "bold",
                marginBottom: "8px"
            }}>
                <div style={{flex: 1, color: theme.palette.text.primary}}>
                    {props.result?.title}
                </div>

                {/* Action Items */}
                <div style={{display: 'flex', alignItems: 'center'}}>
                    {props.result?.object_id && props.result?.type === 'Person' &&
                        <div style={{display: 'inline-block', marginRight: '20px'}}
                             onClick={() => navigate(`/search/person/${props.result?.object_id}`)}>
                            <FaSearch size={20}/>
                        </div>
                    }

                    {props.result?.object_id &&
                        <div style={{display: 'inline-block', marginRight: '20px'}}
                             onClick={() => navigate(`/graph/${props.result?.object_id}`)}>
                            <FaCircleNodes size={20}/>
                        </div>
                    }
                </div>
            </div>

            <div style={{fontSize: "18px", color: theme.palette.text.primary, marginBottom: "8px"}}>
                {props.result?.type}
            </div>
            <Card sx={{backgroundColor: theme.palette.text.secondary}}>
                <div style={{fontSize: "16px", color: theme.palette.text.primary}}>
                    {props.result?.short_description}
                </div>
            </Card>

        </Card>

    );
}