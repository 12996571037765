import {Button, Card, Typography, Grid2} from "@mui/material";
import TalentPoolList from "./talent_pool_list";

export default function TalentPool() {
    return(
        <Grid2 size={12}>
            <Card sx={{marginBottom: '20px', display: 'flex', justifyContent: 'space-between'}}>
                <Typography variant="h5" component="div">Talent Pool</Typography>
                <Button variant={'contained'} sx={{padding: 0}}>Create</Button>
            </Card>
            <TalentPoolList/>
        </Grid2>
    )

}
