import {useEffect, useState} from "react";
import axios from "axios";
import * as React from 'react';
import {
    Grid2,
    Card,
    Box,
    Typography,
    TableRow,
    TableHead,
    TableContainer,
    TableCell,
    TableBody,
    Table
} from '@mui/material';
import {FaSearch, FaEdit} from "react-icons/fa";
import {useNavigate} from "react-router-dom";
import {useTheme} from "@mui/material/styles";

export interface CandidateInterface {
    id: number
    first_name: string
    last_name: string
    linkedin: string
    resume: string
    cover_letter: string
    phone: string
    email: string
    created: Date | undefined
}

interface Props {
    candidate: CandidateInterface
}

function Row(props: Props) {
    const navigate = useNavigate();
    return (
        <React.Fragment>
            <TableRow sx={{'& > *': {borderBottom: 'unset'}}}>
                <TableCell align="left">{props.candidate.first_name} {props.candidate.last_name}</TableCell>
                <TableCell align="left">{props.candidate.phone}</TableCell>
                <TableCell align="left">{props.candidate.email}</TableCell>
                <TableCell align="left">{props.candidate.linkedin}</TableCell>
                <TableCell align="left">{new Date(props.candidate.created!).toLocaleDateString('en-GB')}</TableCell>
                <TableCell align="left">
                    <FaSearch size={20}
                              onClick={() => navigate('/recruitment/candidate/' + props.candidate.id)}></FaSearch>
                    <Box sx={{width: '10px', display: 'inline-block'}}/>
                    <FaEdit size={20}></FaEdit>
                </TableCell>
            </TableRow>
        </React.Fragment>
    );
}

export default function ResumeList() {
    const theme = useTheme()
    const [candidates, setCandidates] = useState<CandidateInterface[]>([])

    useEffect(() => {
        axios.get(process.env.REACT_APP_API_BASE_URL + 'recruitment/candidates',
            {
                withCredentials: true
            }
        ).then(response => {
            setCandidates(response.data.candidates as CandidateInterface[]);
        }).catch(error => {
            console.error(error);
        });
    }, []);


    return (
        <Grid2 size={12}>
            {candidates.length > 0 ? <Card sx={{padding: '0px 10px 10px 10px'}}>

                    <TableContainer className="background-card">
                        <Table aria-label="collapsible table">
                            <TableHead>
                                <TableRow sx={{bgcolor: theme.palette.primary.main}}>
                                    <TableCell align="left">
                                        <Typography variant="h6" component={'div'} color={'secondary'} fontWeight={600}>
                                            Name
                                        </Typography>
                                    </TableCell>
                                    <TableCell align="left">
                                        <Typography variant="h6" component={'div'} color={'secondary'} fontWeight={600}>
                                            Phone
                                        </Typography>
                                    </TableCell>
                                    <TableCell align="left">
                                        <Typography variant="h6" component={'div'} color={'secondary'} fontWeight={600}>
                                            Email
                                        </Typography>
                                    </TableCell>
                                    <TableCell align="left">
                                        <Typography variant="h6" component={'div'} color={'secondary'} fontWeight={600}>
                                            LinkedIn
                                        </Typography>
                                    </TableCell>
                                    <TableCell align="left">
                                        <Typography variant="h6" component={'div'} color={'secondary'} fontWeight={600}>
                                            Date Added
                                        </Typography>
                                    </TableCell>
                                    <TableCell align="left">
                                        <Typography variant="h6" component={'div'} color={'secondary'} fontWeight={600}>
                                            Actions
                                        </Typography>
                                    </TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {candidates && candidates.map((candidate: CandidateInterface) => (
                                    <Row key={candidate.id} candidate={candidate}/>
                                ))}
                            </TableBody>
                        </Table>
                    </TableContainer>
                </Card>
                : <Card variant={'outlined'}>
                    No candidated.
                </Card>
            }
        </Grid2>
    )
}