import ProfileDetails from "./profile_details";
import ProfileContent from "./profile_content";
import {Grid2} from "@mui/material";
import {useParams} from "react-router-dom";


export default function Profile() {
    const params = useParams();

    return (
        <Grid2 container>
            <Grid2 size={3}>
                <ProfileDetails id={params.id}/>
            </Grid2>
            <Grid2 size={9} paddingLeft={'20px'}>
                <ProfileContent id={params.id}/>
            </Grid2>
        </Grid2>
    )
}