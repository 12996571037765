import {useEffect, useState} from "react";
import axios from "axios";
import * as React from 'react';
import {
    Grid2,
    Typography,
    TableRow,
    TableHead,
    TableContainer,
    TableCell,
    TableBody,
    Table,
    Card,
    Box
} from '@mui/material';
import {FaEdit, FaSearch} from "react-icons/fa";
import {useNavigate} from "react-router-dom";
import {useTheme} from "@mui/material/styles";
import {JobDescriptionInterface} from "@/components/recruitment/job_descriptions/job_descriptions";

export interface JobOpeningInterface {
    id?: number
    title: string
    job_description?: JobDescriptionInterface
    department?: string
    salary?: string
}

interface Props {
    job_opening: JobOpeningInterface
}

function Row(props: Props) {
    const navigate = useNavigate();
    return (
        <React.Fragment>
            <TableRow sx={{'& > *': {borderBottom: 'unset'}}}>
                <TableCell align="left">{props.job_opening.title}</TableCell>
                <TableCell align="left">{props.job_opening.job_description?.title}</TableCell>
                <TableCell align="left">{props.job_opening.department}</TableCell>
                <TableCell align="left">{props.job_opening.salary}</TableCell>
                <TableCell align="left">
                    <FaSearch size={20}
                              onClick={() => navigate('/recruitment/job/opening/' + props.job_opening.id)}></FaSearch>
                    <Box sx={{width: '10px', display: 'inline-block'}}/>
                    <FaEdit size={20} onClick={() => navigate('/recruitment/job/opening/edit/' + props.job_opening.id)}></FaEdit>
                </TableCell>
            </TableRow>
        </React.Fragment>
    );
}

export default function JobOpenings() {
    const theme = useTheme()
    const [job_openings, setJobOpenings] = useState<JobOpeningInterface[]>([])

    useEffect(() => {
        axios.get(process.env.REACT_APP_API_BASE_URL + 'recruitment/job/openings',
            {
                withCredentials: true
            }
        ).then(response => {
            setJobOpenings(response.data.job_openings as JobOpeningInterface[]);
        }).catch(error => {
            console.error(error);
        });
    }, []);

    return (
        <Grid2 size={12}>
            {job_openings.length > 0 ? <Card sx={{padding: '0px 10px 10px 10px'}}>
                <TableContainer className="background-card">
                    <Table aria-label="collapsible table">
                        <TableHead>
                            <TableRow sx={{bgcolor: theme.palette.primary.main}}>
                                <TableCell align="left">
                                    <Typography variant="h6" component={'div'} color={'secondary'} fontWeight={600}>
                                        Job Title
                                    </Typography>
                                </TableCell>
                                <TableCell align="left">
                                    <Typography variant="h6" component={'div'} color={'secondary'} fontWeight={600}>
                                        Description
                                    </Typography>
                                </TableCell>
                                <TableCell align="left">
                                    <Typography variant="h6" component={'div'} color={'secondary'} fontWeight={600}>
                                        Department
                                    </Typography>
                                </TableCell>
                                <TableCell align="left">
                                    <Typography variant="h6" component={'div'} color={'secondary'} fontWeight={600}>
                                        Salary
                                    </Typography>
                                </TableCell>
                                <TableCell align="left">
                                    <Typography variant="h6" component={'div'} color={'secondary'} fontWeight={600}>
                                        Actions
                                    </Typography>
                                </TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {job_openings && job_openings.map((job_opening: JobOpeningInterface) => (
                                <Row key={job_opening.id} job_opening={job_opening}/>
                            ))}
                        </TableBody>
                    </Table>
                </TableContainer>
            </Card>
            : <Card variant={'outlined'}>
                No current job openings.
            </Card>

            }

        </Grid2>
    )
}