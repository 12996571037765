import React from 'react';
import { FaUser } from 'react-icons/fa';
import NavItem from "./nav_item";
import { useLocation } from "react-router-dom";
import { Box, List } from "@mui/material";
import { useTheme } from '@mui/material/styles';

interface Props {
    navSelection: string
    setNavSelection: Function
}

export default function Navbar(props: Props) {
    const theme = useTheme();
    const location = useLocation();
    const pathSegments = location.pathname.split('/');
    const lastSegment = pathSegments[pathSegments.length - 1];

    React.useEffect(() => {
        if (lastSegment === 'account') {
            props.setNavSelection('account');
        }
    }, [location, props.setNavSelection]);

    return (
        <Box sx={{
            bgcolor: theme.palette.secondary.main,
        }}>

            <List sx={{ textDecoration: 'none' }}>
                <NavItem navItem={'account'} image={<FaUser size={20} />} navSelection={props.navSelection}
                    setNavSelection={props.setNavSelection} />
            </List>
        </Box>
    );
};