import {MinutesInterface} from "@/components/meeting/meeting";
import MinutesItem from './minutes_item';
import LoadingWheel from "../../../theme/loading";
import Card from "@mui/material/Card";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import {theme} from "src/components/theme/theme";

interface Props {
    minutes: MinutesInterface[]
}

export default function Minutes(props: Props) {
    return (
        <Card sx={{backgroundColor: theme.palette.primary.main, marginBottom: '20px'}}>
            <Box>
                <Typography variant="h5" component="div" sx={{color: 'white', fontWeight: 600}}>
                    Minutes
                </Typography>
            </Box>
            <Box>
                {props.minutes ?
                    <Box component="ul" display="flex" flexDirection="column" p={0} m={0}>
                        {props.minutes.length > 0 ?
                            props.minutes.map((minutes: MinutesInterface) => (
                                <MinutesItem minutes={minutes}/>
                            )) : "No agendas found"}
                    </Box>
                    : <LoadingWheel/>}
            </Box>
        </Card>
    );
}