import {useEffect, useState} from "react";
import axios from "axios";
import * as React from 'react';
import {
    Grid2,
    Card,
    Typography,
    TableRow,
    TableHead,
    TableContainer,
    TableCell,
    TableBody,
    Table,
    Box
} from '@mui/material';
import {FaEdit, FaPlus, FaSearch} from "react-icons/fa";
import {useNavigate} from "react-router-dom";
import {useTheme} from "@mui/material/styles";
import {JobDescriptionInterface} from "src/components/recruitment/job_descriptions/job_descriptions";

interface Props {
    job_description: JobDescriptionInterface
}

function Row(props: Props) {
    const navigate = useNavigate();
    return (
        <React.Fragment>
            <TableRow sx={{'& > *': {borderBottom: 'unset'}}}>
                <TableCell align="left">{props.job_description.title}</TableCell>
                <TableCell
                    align="left">{new Date(props.job_description.created!).toLocaleDateString('en-GB')}</TableCell>
                <TableCell align="left">
                    <FaSearch size={20}
                              onClick={() => navigate('/recruitment/job/description/' + props.job_description.id)}></FaSearch>
                    <Box sx={{width: '10px', display: 'inline-block'}}/>
                    <FaEdit size={20} onClick={() => navigate('/recruitment/job/description/edit/' + props.job_description.id)}></FaEdit>
                </TableCell>
            </TableRow>
        </React.Fragment>
    );
}

export default function JobDescriptionsList() {
    const theme = useTheme()
    const [job_descriptions, setJobDescriptions] = useState<JobDescriptionInterface[]>([])

    useEffect(() => {
        axios.get(process.env.REACT_APP_API_BASE_URL + 'recruitment/job/descriptions',
            {
                withCredentials: true
            }
        ).then(response => {
            setJobDescriptions(response.data.job_descriptions as JobDescriptionInterface[]);
        }).catch(error => {
            console.error(error);
        });
    }, []);


    return (
        <Grid2 size={12}>
            {job_descriptions.length > 0 ? <Card sx={{padding: '0px 10px 10px 10px'}}>
                    <TableContainer className="background-card">
                        <Table aria-label="collapsible table">
                            <TableHead>
                                <TableRow sx={{bgcolor: theme.palette.primary.main}}>
                                    <TableCell align="left">
                                        <Typography variant="h6" component={'div'} color={'secondary'} fontWeight={600}>
                                            Title
                                        </Typography>
                                    </TableCell>
                                    <TableCell align="left">
                                        <Typography variant="h6" component={'div'} color={'secondary'} fontWeight={600}>
                                            Description
                                        </Typography>
                                    </TableCell>
                                    <TableCell align="left">
                                        <Typography variant="h6" component={'div'} color={'secondary'} fontWeight={600}>
                                            Actions
                                        </Typography>
                                    </TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {job_descriptions && job_descriptions.map((job_description: JobDescriptionInterface) => (
                                    <Row key={job_description.id} job_description={job_description}/>
                                ))}
                            </TableBody>
                        </Table>
                    </TableContainer>
                </Card>
                : <Card variant={'outlined'}>
                    No job descriptions.
                </Card>

            }
        </Grid2>
    )
}