import {useEffect, useState} from "react";
import axios from "axios";
import * as React from 'react';
import {Grid2, Card, Typography, TableRow, TableHead, TableContainer, TableCell, TableBody, Table} from '@mui/material';
import {FaPlus, FaSearch} from "react-icons/fa";
import {useNavigate} from "react-router-dom";
import {useTheme} from "@mui/material/styles";

export interface MeetingInterface {
    id: number
    title: string
    description: string
    location: string | undefined
    start_datetime: string | undefined
    end_datetime: string | undefined
}

export interface AttendeeInterface {
    id: number
    email: string
}

export interface AgendaInterface {
    id: number
    content: string
    created: Date | undefined
}

export interface MinutesInterface {
    id: number
    content: string
    created: Date | undefined
}


interface MeetingItemProps {
    meeting: MeetingInterface
}

function Row(props: MeetingItemProps) {
    const navigate = useNavigate();
    return (
        <React.Fragment>
            <TableRow sx={{'& > *': {borderBottom: 'unset'}}}>
                <TableCell align="left">{props.meeting.title}</TableCell>
                <TableCell align="left">{props.meeting.description}</TableCell>
                <TableCell align="left"><FaSearch
                    onClick={() => navigate('/meeting/' + props.meeting.id)}></FaSearch><FaPlus></FaPlus></TableCell>
            </TableRow>
        </React.Fragment>
    );
}

export default function Meeting() {
    const theme = useTheme()
    const [meetings, setMeetings] = useState<MeetingInterface[]>([])

    useEffect(() => {
        axios.get(process.env.REACT_APP_API_BASE_URL + 'meeting/list',
            {
                withCredentials: true
            }
        ).then(response => {
            setMeetings(response.data as MeetingInterface[]);
        }).catch(error => {
            console.error(error);
        });
    }, []);


    return (
        <Grid2 size={12}>
            <Card sx={{padding: '0px 10px 10px 10px'}}>

                <TableContainer className="background-card">
                    <Table aria-label="collapsible table">
                        <TableHead>
                            <TableRow sx={{bgcolor: theme.palette.primary.main}}>
                                <TableCell align="left">
                                    <Typography variant="h6" component={'div'} color={'secondary'} fontWeight={600}>
                                        Title
                                    </Typography>
                                </TableCell>
                                <TableCell align="left">
                                    <Typography variant="h6" component={'div'} color={'secondary'} fontWeight={600}>
                                        Description
                                    </Typography>
                                </TableCell>
                                <TableCell align="left">
                                    <Typography variant="h6" component={'div'} color={'secondary'} fontWeight={600}>
                                        Actions
                                    </Typography>
                                </TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {meetings && meetings.map((meeting: MeetingInterface) => (
                                <Row key={meeting.id} meeting={meeting}/>
                            ))}
                        </TableBody>
                    </Table>
                </TableContainer>
            </Card>
        </Grid2>
    )
}