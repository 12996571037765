import * as React from 'react';
import {Card, Modal, Typography, TextField, FormControl, FormLabel, Button, Box, Grid2} from '@mui/material'
import {useTheme} from "@mui/material/styles";

export default function Privacy() {
    const theme = useTheme();

    return (
        <Box minHeight={'100vh'}
             id={'privacy'}
             sx={{
            'backgroundColor': theme.palette.background.default, display: 'flex', position: 'relative',alignItems: 'center', justifyContent: 'center'
        }}>
            <Box
                sx={{
                    maxWidth: '70%',
                    margin: '0 auto',
                    pt: '130px'
                }}>

                <Typography variant="h3" component="h2"
                            sx={{marginBottom: 2, color: theme.palette.text.primary}}>
                    we take privacy seriously.
                </Typography>

                <Grid2 container alignItems="center" sx={{pt: '20px'}}>
                    <Grid2 size={4} sx={{ display: 'flex', justifyContent: 'flex-end', textAlign: 'right', p:'10px' }}>
                        <Typography variant="h4" component="h2" sx={{ marginBottom: 2, color: theme.palette.text.primary }}>
                            access.
                        </Typography>
                    </Grid2>
                    <Grid2 size={8} sx={{ display: 'flex', justifyContent: 'flex-start', textAlign:'left', p:'10px' }}>
                        <Typography variant="body1" component="h2" sx={{ marginBottom: 2, color: theme.palette.text.primary }}>
                            OrgAtlas is restricted to your domain. Only people with an email administered from your organisation are able to register.
                        </Typography>
                    </Grid2>
                </Grid2>

                <Grid2 container alignItems="center" sx={{pt: '20px'}}>
                    <Grid2 size={4} sx={{ display: 'flex', justifyContent: 'flex-end', textAlign: 'right', p:'10px' }}>
                        <Typography variant="h4" component="h2" sx={{ marginBottom: 2, color: theme.palette.text.primary }}>
                            technology.
                        </Typography>
                    </Grid2>
                    <Grid2 size={8} sx={{ display: 'flex-block', justifyContent: 'flex-start', textAlign:'left', p:'10px' }}>
                        <Typography variant="body1" component="h2" sx={{ color: theme.palette.text.primary }}>
                            Your company's installation of OrgAtlas operates on its own isolated database.
                        </Typography>
                        <Typography variant="body1" component="h2" sx={{ color: theme.palette.text.primary }}>
                            Built on a secure tech stack with HTTPS encryption.
                        </Typography>
                        <Typography variant="body1" component="h2" sx={{color: theme.palette.text.primary }}>
                            Our infrastructure is located in Australia.
                        </Typography>
                        <Typography variant="body1" component="h2" sx={{ color: theme.palette.text.primary }}>
                            Your data is never used for external analysis or model training.
                        </Typography>
                    </Grid2>
                </Grid2>

                <Grid2 container alignItems="center" sx={{pt: '20px'}}>
                    <Grid2 size={4} sx={{ display: 'flex', justifyContent: 'flex-end', textAlign: 'right', p:'10px' }}>
                        <Typography variant="h4" component="h2" sx={{ marginBottom: 2, color: theme.palette.text.primary }}>
                            design.
                        </Typography>
                    </Grid2>
                    <Grid2 size={8} sx={{ display: 'flex', justifyContent: 'flex-start', textAlign:'left', p:'10px' }}>
                        <Typography variant="body1" component="h2" sx={{ marginBottom: 2, color: theme.palette.text.primary }}>
                            OrgAtlas is designed to thoughtfully abstract information into a form that is useful for people within your organisation, but not very useful for anyone else.
                        </Typography>
                    </Grid2>
                </Grid2>

            </Box>
        </Box>
    )
        ;
}